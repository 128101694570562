@import '../../colours.scss';

.SkillCardContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 35px;

	.SkillCardImage {
		width: 160px;
		height: 120px;
	}

	.SkillCard {
		height: 100px;
		width: 100px;
	}

	span {
		font-weight: bold;
	}
}
