.ProjectOuterContainer {
	max-width: 1000px;
	padding: 100px;

	.ProjectInnerContainer {
		display: flex;
		flex-direction: row;

		.ProjectImageContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 450px;
			}
		}

		.ProjectDescriptionContainerLeft,
		.ProjectDescriptionContainerRight {
			line-height: 1.5;
			font-size: 20px;
		}

		.ProjectDescriptionContainerRight {
			padding-left: 50px;
			text-align: left;
		}

		.ProjectDescriptionContainerLeft {
			padding-right: 50px;
			text-align: right;
		}
	}
}
