@import '../../colours.scss';

.SkillsPageOuterContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.SkillsPageHeader {
		margin-bottom: 20px;

		h1 {
			border-bottom: 4px solid $base-orange;
		}
	}

	.SkillsContainer {
		display: flex;
		max-width: 1300px;
		padding: 0px 50px 50px 50px;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}
}

@media only screen and (max-width: 575px) {
	.SkillCardContainer {
		margin: 10px;
	}

	.SkillCardContainer {
		.SkillCardImage {
			width: 100px;
		}

		.SkillCard {
			width: 80px;
			height: 80px;
		}
	}
}

@media only screen and (max-width: 465px) {
	.SkillsPageOuterContainer .SkillsContainer {
		padding: 0px 40px 40px 40px;
	}
}
