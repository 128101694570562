@import '../../colours.scss';

.AboutPageOuterContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.AboutPageHeader {
		margin-bottom: 20px;

		h1 {
			border-bottom: 4px solid $base-orange;
		}
	}

	.AboutPageContent {
		display: flex;
		flex-direction: row;
		width: 70%;
		align-items: center;
		justify-content: center;
		max-width: 1000px;

		.AboutPageImage {
			img {
				width: 300px;
			}
		}

		.AboutPagePersonalDescription {
			padding: 30px;

			p {
				font-size: 20px;
				text-align: justify;
			}

			ul {
				display: flex;
				list-style-type: none;
				justify-content: space-between;
				li {
					&:hover {
						border-bottom: 4px solid $base-orange;
						margin-bottom: -4px;
					}

					a {
						color: black;
						&:visited {
							color: black;
						}

						svg,
						img {
							height: 35px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1050px) {
	.AboutPageOuterContainer .AboutPageContent {
		flex-direction: column;
	}

	.AboutPageOuterContainer .AboutPageContent .AboutPagePersonalDescription ul {
		padding-inline-start: 0;
	}
}

@media only screen and (max-width: 590px) {
	.AboutPageOuterContainer .AboutPageContent .AboutPagePersonalDescription {
		padding: 0;
	}

	.AboutPageOuterContainer .AboutPageContent .AboutPageImage img {
		width: 200px;
	}
}
