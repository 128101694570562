@import '../../colours.scss';

.SeparatorContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 60px 0px;

	.Separator {
		border-bottom: 4px solid $base-orange;
		width: 60vw;
	}
}
