@import '../../colours.scss';

.ContactPageOuterContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 100px;

	.ContactPageHeader {
		margin-bottom: 20px;

		h1 {
			border-bottom: 4px solid $base-orange;
		}
	}
}

.swal2-confirm {
	background-color: $base-orange !important;
	color: white !important;
}

form {
	display: flex;
	flex-direction: column;
	text-align: left;

	span {
		margin-bottom: 8px;
		font-weight: bold;
	}

	p {
		color: $error-red;
	}

	input,
	textarea {
		width: 600px;
		line-height: 2;
		border-radius: 3px;
		border: none;
		outline: 2px solid black;

		&:nth-child(8) {
			height: 200px;
			align-self: flex-start;
		}
	}

	textarea {
		resize: none;
	}

	input[type='submit'] {
		background-color: $base-orange;
		color: white;
		border: none;
		outline: none;
		padding: 5px;
		font-size: 1.25rem;
		border-radius: 5px;

		&:hover {
			cursor: pointer;
		}
	}
}

@media only screen and (max-width: 700px) {
	form input,
	form textarea {
		width: 300px;
	}
}
