@import '../../colours.scss';

.NavigationBarOuterContainer {
	width: 100%;

	.NavigationBarInnerContainer {
		width: 85%;
	}

	nav > ul {
		list-style-type: none;
		display: flex;
		justify-content: flex-end;

		li {
			padding: 15px;
			font-size: 20px;

			&:hover {
				border-bottom: 4px solid $base-orange;
				margin-bottom: -4px;
			}

			a {
				text-decoration: none;
				font-weight: bold;
				color: black;

				&:visited {
					color: black;
				}
			}
		}
	}
}

@media only screen and (max-width: 790px) {
	.NavigationBarOuterContainer .NavigationBarInnerContainer {
		width: 100%;

		nav > ul {
			justify-content: center;
			padding-inline-start: 0px;
		}
	}
}
