@import '../../colours.scss';

.CurrentProjectsPageOuterContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.CurrentProjectsPageHeader {
		margin-bottom: 20px;

		h1 {
			border-bottom: 4px solid $base-orange;
		}
	}
}

.MobileScreenProjectsContainer {
	display: none;
}

@media only screen and (max-width: 1100px) {
	.LargeScreenProjectsContainer {
		display: none;
	}

	.MobileScreenProjectsContainer {
		display: flex;
		flex-direction: column;
	}

	.CurrentProjectsPageOuterContainer {
		padding: 50px;

		.ProjectInnerContainer {
			flex-direction: column;

			.ProjectDescriptionContainerRight {
				padding: 0;
				max-width: 600px;
				text-align: justify;

				h2 {
					text-align: center;
				}
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	.ProjectOuterContainer .ProjectInnerContainer .ProjectImageContainer img {
		width: 300px;
	}

	.ProjectOuterContainer {
		.ProjectInnerContainer {
			.ProjectDescriptionContainerRight {
			}
		}
	}
}
