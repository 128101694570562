@import '../../colours.scss';

.HomePageOuterContainer {
	height: 100vh;

	.HomePageTitleOuterContainer {
		display: flex;
		justify-content: center;
		width: 70vw;

		.HomePageTitleContainer {
			height: 50vh;
			display: flex;
			align-items: start;
			margin-left: 80px;
			justify-content: end;
			flex-direction: column;
			font-size: 1.5rem;
			max-width: 1000px;

			h1 {
				margin: 10px;

				&:first-child {
					border-bottom: 4px solid $base-orange;
				}
			}
		}
	}
}

@media only screen and (max-width: 790px) {
	.HomePageOuterContainer .HomePageTitleOuterContainer {
		width: 100vw;
		.HomePageTitleContainer {
			//font-size: 1rem;
			margin-left: 0;
			align-items: center;
			max-width: unset;
		}
	}
}

@media only screen and (max-width: 500px) {
	.HomePageOuterContainer .HomePageTitleOuterContainer .HomePageTitleContainer {
		font-size: 1rem;
	}
}
